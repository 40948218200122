import { i18n } from '@/i18n-setup';

export abstract class NumberHelper {
  static formatToLocaleDecimal(value: number): string {
    return `${i18n.n(value, 'decimal')}`;
  }

  static formatToLocaleCurrency(value: number): string {
    return `${i18n.n(value, 'currency')}`;
  }
}
