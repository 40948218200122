export abstract class ArrayHelper {
  static filterByKeyword<T>(items: T[], searchableKeys: string[], keyword = ''): T[] {
    const regexp = new RegExp(keyword, 'i');

    return items.filter((item) => {
      const result = searchableKeys.find(
        (column) => (item[column] || '').toString().search(regexp) > -1,
      );
      return result;
    });
  }
}
